@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

html, body {
  font-family: 'Roboto Slab', Arial, Helvetica, sans-serif;
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 0;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: white;
}
a.active {
  color: rgb(102, 168, 225)
}

header img {
  height: 300px;
  padding-top: 5vh;
  display: flex;
  margin: auto;

}

article {
  border: solid rgb(41, 41, 41) 1px;
  border-radius: 25px;
  box-shadow: 0px 0px 3px 3px rgb(24, 46, 92);
  margin: 10px 1vw;
  padding: 25px 40px
}

h1 {
  text-align: center;
}


nav {
  display: flex;
  width: 100vw;
  margin-top: 10px;
  justify-content: space-evenly;
  font-size: 40px;
  text-decoration: none;
}

#homebanner {
  width: 100vw;
}

#socials {
  width: 200px;
  display: flex;
  margin: auto;
  padding-top: 5px;
  justify-content: space-evenly;
}
